<template>
  <v-container fluid>
    <v-row
      align="center"
      class="px-10"
      dense
    >
      <v-col
        cols="12"
        md="6"
      >
        <v-radio-group
          class="ma-0 pt-2"
          v-model="client.person_type"
          mandatory
          :disabled="client.id != null"
          row
          @change="clearDocument"
        >
          <v-radio
            class="ml-5"
            label="Pessoa jurídica"
            value="PJ"
          />
          <v-radio
            label="Pessoa física"
            value="PF"
          />
        </v-radio-group>
      </v-col>
      <v-col
        cols="12"
        align="end"
        md="6"
      >
        <label class="text-subtitle-2 mr-3">Humor</label>
        <v-btn-toggle
          v-model="client.mood_id"
          rounded
        >
          <v-btn
            v-for="(moodButton, idx) in moodButtons"
            :key="idx"
            :value="moodButton.id"
          >
            <v-icon :color="moodButton.color">{{ moodButton.icon }}</v-icon>
            <span
              v-show="moodButton.id === client.mood_id"
              class="text-caption ml-1"
              >{{ moodButton.label }}</span
            >
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row
      class="mt-4"
      dense
    >
      <v-col
        cols="12"
        md="4"
        xl="4"
      >
        <v-autocomplete
          v-model="client.seller_id"
          label="Vendedor*"
          :items="sellers"
          :rules="[() => !!client.seller_id || 'Este campo é obrigatório']"
          :item-text="'title'"
          item-value="id"
          dense
          rounded
          outlined
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        xl="4"
      >
        <v-autocomplete
          v-model="client.agency_id"
          label="Agência"
          :items="agencies"
          item-text="company_name"
          item-value="id"
          dense
          rounded
          outlined
          @selected="getSellerID(item)"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        xl="4"
      >
        <v-autocomplete
          :rules="[() => !!client.business_id || 'Este campo é obrigatório']"
          v-model="client.business_id"
          label="Ramo de Atuação*"
          :items="lineOfBusiness"
          item-text="name"
          item-value="id"
          dense
          rounded
          outlined
        />
      </v-col>
      <component
        :client="client"
        :is="client.person_type"
      />
      <v-col cols="12">
        <v-text-field
          v-model="client.website"
          label="Site"
          placeholder="https://exemplo.com.br"
          dense
          rounded
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PF from './PF'
import PJ from './PJ'
export default {
  props: {
    client: Object,
    lineOfBusiness: Array,
    agencies: Array,
    sellers: Array,
    moods: Array,
  },
  data: () => ({
    moodButtons: [
      {
        id: 1,
        label: 'Feliz',
        icon: 'fas fa-smile-beam',
        color: 'success darken-1',
      },
      { id: 2, label: 'Pouco feliz', icon: 'fas fa-smile', color: 'cyan' },
      { id: 3, label: 'Normal', icon: 'fas fa-meh', color: 'grey' },
      {
        id: 4,
        label: 'Pouco infeliz',
        icon: 'fas fa-frown',
        color: 'yellow darken-3',
      },
      { id: 5, label: 'Infeliz', icon: 'fas fa-angry', color: 'error' },
    ],
  }),
  components: {
    PF,
    PJ,
  },
  methods: {
    clearDocument() {
      console.log('disparando', this.client)
      this.client.cpf = null
      this.client.cnpj = null
      this.client.name = null
      this.client.company_name = null
    },
    getSellerID(item) {
      let idx = this.agencies.indexOf(item)
      if (this.agencies[idx].seller_id != null)
        this.client.seller_id = this.agencies[idx].seller_id
    },
  },
}
</script>
